import { getFormValues, change, SubmissionError, reset } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";
import * as customerService from "../customer/customer.service";
import * as customerSettingsService from "./customerSettings.service";

import {
  CUSTOMER_SETTINGS,
  CUSTOMER_SETTINGS_SEARCH_FORM,
} from "../../constants/forms";
import { ignoreCatch } from "../../utils/async";
import { DPD_BUSINESS_ID } from "../userDetailsForm/accountDetails/accountDetails.constants";
import { createLoadingAction } from "../loader/loader.actions";
import { showInfoModal } from "../modal/modal.actions";
import { customerCustomerSettingsFromForms } from "./customerSettings.models";
import {
  ACCOUNT_FIELD,
  BUSINESS_ID_FIELD,
  CUSTOMER_SETTINGS_ERRORS,
  SPECIAL_SERVICE_EXCLUSIONS_FIELD,
} from "./customerSettings.constants";

const ACTION_NAMESPACE = "CUSTOMER_SETTINGS";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  SEARCH_ACCOUNT: createAsyncActionTypes("SEARCH_ACCOUNT"),
  FETCH_CUSTOMER: createAsyncActionTypes("FETCH_CUSTOMER"),
  LOAD_ACCOUNT_SETTINGS: createAsyncActionTypes("LOAD_ACCOUNT_SETTINGS"),
  FETCH_ACCOUNT_SETTINGS: createAsyncActionTypes("FETCH_ACCOUNT_SETTINGS"),
  SAVE_CUSTOMER_SETTINGS: createAsyncActionTypes("SAVE_CUSTOMER_SETTINGS"),
});

export const clearAccountField = () => (dispatch) =>
  dispatch(change(CUSTOMER_SETTINGS_SEARCH_FORM, ACCOUNT_FIELD, ""));

export const resetCustomerSettingsForm = () => (dispatch) =>
  dispatch(reset(CUSTOMER_SETTINGS));

export const fetchAccountSettings = createAsyncAction(
  customerSettingsService.getCustomerSettings,
  ACTIONS.FETCH_ACCOUNT_SETTINGS
);

export const fetchCustomer = createAsyncAction(
  customerService.getCustomerByAccountNumber,
  ACTIONS.FETCH_CUSTOMER
);

export const onSearchAccount = createAsyncAction(
  createLoadingAction(() => async (dispatch, getState) => {
    const values = getFormValues(CUSTOMER_SETTINGS_SEARCH_FORM)(getState());
    const account = await dispatch(
      fetchCustomer(values[ACCOUNT_FIELD], values[BUSINESS_ID_FIELD])
    );

    if (!account) {
      throw new SubmissionError({
        [ACCOUNT_FIELD]: CUSTOMER_SETTINGS_ERRORS.ACCOUNT_WAS_NOT_FOUND,
      });
    }

    if (account.business !== values.businessId) {
      const message =
        values.businessId === DPD_BUSINESS_ID
          ? CUSTOMER_SETTINGS_ERRORS.ACCOUNT_WAS_NOT_FOUND_FOR_DPD
          : CUSTOMER_SETTINGS_ERRORS.ACCOUNT_WAS_NOT_FOUND_FOR_DPD_LOCAL;

      throw new SubmissionError({
        [ACCOUNT_FIELD]: message,
      });
    }
  }),
  ACTIONS.SEARCH_ACCOUNT
);

export const loadAccountSettingsFormValues = createAsyncAction(
  createLoadingAction(() => async (dispatch, getState) => {
    const values = getFormValues(CUSTOMER_SETTINGS_SEARCH_FORM)(getState());
    const accountSettings = await ignoreCatch(
      dispatch(
        fetchAccountSettings(values[ACCOUNT_FIELD], values[BUSINESS_ID_FIELD])
      )
    );

    if (accountSettings) {
      dispatch(
        change(
          CUSTOMER_SETTINGS,
          SPECIAL_SERVICE_EXCLUSIONS_FIELD,
          accountSettings?.specialServices || []
        )
      );
    }
  }),
  ACTIONS.LOAD_ACCOUNT_SETTINGS
);

export const onSaveAccountSettings = createAsyncAction(
  createLoadingAction(() => async (dispatch, getState) => {
    const state = getState();
    const searchFormValues = getFormValues(CUSTOMER_SETTINGS_SEARCH_FORM)(
      state
    );
    const customerSettings = getFormValues(CUSTOMER_SETTINGS)(state);
    const customerAccountSettings = customerCustomerSettingsFromForms(
      searchFormValues,
      customerSettings
    );

    await customerSettingsService.updateCustomerSettings(
      customerAccountSettings
    );

    const ACCOUNT_SETTINGS_UPDATED_SUCCESSFULLY =
      "Account settings has been updated successfully";
    dispatch(showInfoModal(ACCOUNT_SETTINGS_UPDATED_SUCCESSFULLY));
  }),
  ACTIONS.SAVE_CUSTOMER_SETTINGS
);
