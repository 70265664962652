import { Grid, Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import { addMinutes, format } from "date-fns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Field, FieldArray } from "redux-form";

import {
  createLogEntryPayload,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { CheckBoxGroup } from "../../../../components/CheckBoxGroup/CheckBoxGroup";
import HeaderWithStar from "../../../../components/HeaderWithStar/HeaderWithStar";
import SelectItem from "../../../../components/SelectItem/SelectItem";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import CustomSwitchWithTitle from "../../../../components/SwitchWidthTitle/CustomSwitchWithTitle";
import WarningText from "../../../../components/WarningText/WarningText";
import { CREATE_EDIT_CUSTOMER_USER_ANALYTICS } from "../../../../constants/analytics";
import {
  END_OF_DAY_DASHED,
  EOD_DAYS,
  EOD_DISABLE_PRINT,
  EOD_ENABLE_RELEASE_FILE,
  EOD_EXTENDED_HOLD,
  EOD_EXTENDED_TITLE,
  EOD_HOLD_EVERYTHING,
  EOD_MASTER,
  EOD_MASTER_TITLE,
  EOD_MASTER_WARNING,
  EOD_MASTER_WARNING2,
  EOD_MASTER_WARNING2_ROWS,
  EOD_MASTER_WARNING_ROWS,
  EOD_TIME,
  EOD_TIME_DEFAULT,
  EOD_TIME_WARNING,
  EOD_WARNING,
  FRIDAY,
  MONDAY,
  SATURDAY,
  SUNDAY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
  WEEKDAY_OPTIONS,
} from "../../../../constants/strings";
import {
  deselectEodMaster,
  onEnableDisableNotification,
  resetEndOfDay,
} from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.actions";
import {
  AUTO_HOLD_FIELD,
  ENABLE_RELEASE_FILE_FIELD,
  EOD_DAY_FIELD,
  EOD_DISABLE_PRINT_MANIFEST_FIELD,
  EOD_MASTER_FIELD,
  EOD_TIME_FIELD,
  EOD_USER_FIELD,
  EXTENDED_HOLD_FIELD,
} from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.constants";
import {
  isEodMasterEnabled,
  isEodMasterSelected,
  isReleaseFileDisabled,
  shouldShowEndOfDay,
} from "../../../../features/userDetailsForm/shippingSettings/shippingSettings.selectors";
import { isMasterEodExist } from "../../../../features/userDetailsForm/userDetailsForm.selector";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import styles from "./EndOfDay.module.scss";

const SelectWithError = withErrorTooltip(SelectWithTitle);

const CheckBoxGroupContainer = ({ ...rest }) => (
  <Box display="flex" flexDirection="row" justifyContent="space-between">
    <CheckBoxGroup {...rest} />
  </Box>
);

const CheckBoxGroupWithError = withErrorTooltip(CheckBoxGroupContainer);

const EndOfDay = ({
  onInputChange,
  showEod,
  releaseFileDisabled,
  isEodMasterDisabled,
  masterEodExists,
  onChangeWeekDay,
  onClickTimeDropdown,
  onClickEndDay,
  onEnableDisableNotification,
  onChangePrintManifest,
}) => {
  const getSelectItems = () => {
    let fromDate = new Date("January 1 2019 17:00");
    const toDate = new Date("January 1 2019 23:01");
    const array = [];

    do {
      array.push(format(fromDate, "HH:mm"));
      fromDate = addMinutes(fromDate, 30);
    } while (fromDate < toDate);

    return array.map((item) => (
      <MenuItem key={item} value={item}>
        <SelectItem title={item} />
      </MenuItem>
    ));
  };

  const renderWarningItems = (row) =>
    row.map((item, index) => (
      <WarningText text={item} key={index} customClass={styles.subItem} />
    ));

  return (
    <Grid container direction="column" spacing={5}>
      <WarningText text={EOD_WARNING} customClass={styles.warnText} />
      <Grid container item direction="column" justify="space-between">
        <Field
          name={EOD_USER_FIELD}
          component={CustomSwitchWithTitle}
          type="checkbox"
          label={END_OF_DAY_DASHED}
          disabled={masterEodExists}
          onChange={(event, value) => {
            onInputChange(EOD_USER_FIELD, value);
            onEnableDisableNotification(value);
            if (!value) {
              return onClickEndDay();
            }
          }}
        />
      </Grid>
      {!!showEod && (
        <>
          <Grid
            container
            item
            justify="space-between"
            direction="row"
            alignItems="center"
            className={styles.selectContainer}
          >
            <Grid item>
              <HeaderWithStar text={EOD_TIME} required />
            </Grid>
            <Grid item>
              <Field
                component={SelectWithError}
                displayEmpty
                renderValue={(value) => value || EOD_TIME_DEFAULT}
                name={EOD_TIME_FIELD}
                className={styles.dropdown}
                onChange={onClickTimeDropdown}
                tooltipClasses={{
                  tooltipPlacementTop: styles.tooltipPlacementTop,
                }}
              >
                {getSelectItems()}
              </Field>
            </Grid>
          </Grid>
          <WarningText text={EOD_TIME_WARNING} customClass={styles.warnText} />
          <Grid item className={styles.withoutPadding}>
            <HeaderWithStar text={EOD_DAYS} required />
            <Grid>
              <FieldArray
                name={EOD_DAY_FIELD}
                component={CheckBoxGroupWithError}
                checkBoxData={WEEKDAY_OPTIONS}
                onChange={onChangeWeekDay}
                tooltipClasses={{
                  tooltipPlacementTop: styles.tooltipPlacementTop,
                }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container item direction="row" spacing={5}>
            <Grid item>
              <Field
                name={AUTO_HOLD_FIELD}
                component={CustomSwitchWithTitle}
                type="checkbox"
                label={EOD_HOLD_EVERYTHING}
                onChange={(event, value) => {
                  onInputChange(AUTO_HOLD_FIELD, value);
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name={ENABLE_RELEASE_FILE_FIELD}
                component={CustomSwitchWithTitle}
                type="checkbox"
                label={EOD_ENABLE_RELEASE_FILE}
                disabled={releaseFileDisabled}
                onChange={(event, value) => {
                  onInputChange(ENABLE_RELEASE_FILE_FIELD, value);
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name={EOD_DISABLE_PRINT_MANIFEST_FIELD}
                component={CustomSwitchWithTitle}
                type="checkbox"
                label={EOD_DISABLE_PRINT}
                onChange={onChangePrintManifest}
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid item>
            <HeaderWithStar text={EOD_EXTENDED_TITLE} />
            <Field
              name={EXTENDED_HOLD_FIELD}
              component={CustomSwitchWithTitle}
              type="checkbox"
              label={EOD_EXTENDED_HOLD}
              onChange={(event, value) => {
                onInputChange(EXTENDED_HOLD_FIELD, value);
              }}
            />
          </Grid>
          <Grid item className={styles.withoutPadding}>
            <HeaderWithStar text={EOD_MASTER_TITLE} />
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Field
                  name={EOD_MASTER_FIELD}
                  component={CustomSwitchWithTitle}
                  type="checkbox"
                  label={EOD_MASTER}
                  disabled={isEodMasterDisabled}
                  updateFocus={() => null}
                  onChange={(event, value) => {
                    onInputChange(EOD_MASTER_FIELD, value);
                  }}
                />
              </Grid>
              <Grid item>
                <WarningText
                  text={EOD_MASTER_WARNING}
                  customClass={styles.withPadding}
                />
                {renderWarningItems(EOD_MASTER_WARNING_ROWS)}
                <br />
                <WarningText
                  text={EOD_MASTER_WARNING2}
                  customClass={styles.withPadding}
                />
                {renderWarningItems(EOD_MASTER_WARNING2_ROWS)}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

EndOfDay.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  showEod: PropTypes.bool,
  releaseFileDisabled: PropTypes.bool,
  isEodMasterDisabled: PropTypes.bool,
  masterEodExists: PropTypes.bool,
  enableEndOfDay: PropTypes.func,
  onClickEndDay: PropTypes.func,
  onChangeWeekDay: PropTypes.func,
  onEnableDisableNotification: PropTypes.func,
  onChangePrintManifest: PropTypes.func,
  onClickTimeDropdown: PropTypes.func,
};

const mapStateToProps = (state) => ({
  showEod: shouldShowEndOfDay(state),
  releaseFileDisabled: isReleaseFileDisabled(state),
  eodMasterSelected: isEodMasterSelected(state),
  isEodMasterDisabled: !isEodMasterEnabled(state),
  masterEodExists: isMasterEodExist(state),
});

const mapDispatchToProps = {
  onClickEndDay: resetEndOfDay,
  onEnableDisableNotification,
  deselectEodMaster,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps(({ deselectEodMaster, eodMasterSelected }) => ({
    onInputChange: (field, value) =>
      createLogEntryPayload(CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_TOGGLE, {
        field,
        value,
      }),
    onClickTimeDropdown:
      CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_TIME_DROPDOWN_LIST,
    onChangePrintManifest: (event, value) => {
      !value && eodMasterSelected && deselectEodMaster();
      return createLogEntryPayload(
        CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_TOGGLE,
        {
          field: EOD_DISABLE_PRINT_MANIFEST_FIELD,
          value,
        }
      );
    },
    onChangeWeekDay: (event) => {
      let id = "";
      switch (event.target.name) {
        case SUNDAY:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_SUNDAY_CHECKBOX;
          break;
        case MONDAY:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_MONDAY_CHECKBOX;
          break;
        case TUESDAY:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_TUESDAY_CHECKBOX;
          break;
        case WEDNESDAY:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_WEDNESDAY_CHECKBOX;
          break;
        case THURSDAY:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_THURSDAY_CHECKBOX;
          break;
        case FRIDAY:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_FRIDAY_CHECKBOX;
          break;
        case SATURDAY:
          id =
            CREATE_EDIT_CUSTOMER_USER_ANALYTICS.CLICK_AUTO_EOD_SATURDAY_CHECKBOX;
          break;
      }
      return createLogEntryPayload(id, {
        checked: event.target.checked,
        day: event.target.name,
      });
    },
  }))
)(EndOfDay);
