import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import styles from "./DpdUsersMenu.module.scss";
import {
  ANALYTICS_FOR_USER,
  MAIN_MENU_FIRST_PHASE,
} from "../../../constants/analytics";
import {
  CREATE_DPD_USER,
  CREATE_USER,
  CUSTOMER_MIGRATION,
  CUSTOMER_SETTINGS,
  DPD_APP_CONFIG_MENU,
  PROFILE_MANAGEMENT,
  SEARCH,
} from "../../../constants/strings";
import { setTabsState } from "../../../features/tabs/tabs.actions";
import {
  COLLAPSE_ALL_STATE,
  EXPAND_ALL_STATE,
} from "../../../features/tabs/tabs.constants";
import {
  isCurrentUserCanAddDpdUsers,
  isCurrentUserAppManagement,
} from "../../../features/user/user.selectors";
import {
  DPD_CREATE_CUSTOMER_USER_PAGE,
  DPD_CREATE_USER_PAGE,
  DPD_MIGRATIONS_PAGE,
  DPD_CUSTOMER_SETTINGS,
  DPD_APP,
  DPD_PROFILES_PAGE,
  DPD_SEARCH_PAGE,
  DPD_UPDATE_FOUND_USER,
} from "../../../router";
import ExpandCollapseAll from "../../ExpandCollapseAll/ExpandCollapseAll";
import MenuItem from "../../MenuItem/MenuItem";

const DpdUsersMenu = ({
  canAddDpdUsers,
  canAppManagement,
  onExpandAll,
  onCollapseAll,
  onSearchClick,
  onProfileManagementClick,
  onCreateDpdClick,
  onCreateUserClick,
}) => (
  <Grid item>
    <List component="nav">
      <MenuItem text={SEARCH} to={DPD_SEARCH_PAGE} onClick={onSearchClick} />

      {canAddDpdUsers && (
        <MenuItem
          text={CREATE_DPD_USER}
          to={DPD_CREATE_USER_PAGE}
          onClick={onCreateDpdClick}
        />
      )}

      <MenuItem
        text={CREATE_USER}
        to={DPD_CREATE_CUSTOMER_USER_PAGE}
        onClick={onCreateUserClick}
      />
      <MenuItem
        text={PROFILE_MANAGEMENT}
        to={DPD_PROFILES_PAGE}
        onClick={onProfileManagementClick}
      />
      <MenuItem text={CUSTOMER_MIGRATION} to={DPD_MIGRATIONS_PAGE} />
      <MenuItem text={CUSTOMER_SETTINGS} to={DPD_CUSTOMER_SETTINGS} />
      {canAppManagement && <MenuItem text={DPD_APP_CONFIG_MENU} to={DPD_APP} />}
    </List>
    <Route
      path={[DPD_CREATE_CUSTOMER_USER_PAGE, DPD_UPDATE_FOUND_USER]}
      component={() => (
        <section className={styles["expand-collapse"]}>
          <ExpandCollapseAll
            onExpandAllClick={onExpandAll}
            onCollapseAllClick={onCollapseAll}
          />
        </section>
      )}
    />
  </Grid>
);

DpdUsersMenu.propTypes = {
  canAddDpdUsers: PropTypes.bool,
  onExpandAll: PropTypes.func,
  onCollapseAll: PropTypes.func,
  onSearchClick: PropTypes.func,
  onProfileManagementClick: PropTypes.func,
  onCreateDpdClick: PropTypes.func,
  onCreateUserClick: PropTypes.func,
};

const mapStateToProps = (state) => ({
  canAddDpdUsers: isCurrentUserCanAddDpdUsers(state),
  canAppManagement: isCurrentUserAppManagement(state),
});

const mapDispatchToProps = (dispatch) => ({
  onExpandCollapseClick: (tabsState) => dispatch(setTabsState(tabsState)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps((props) => ({
    onExpandAll: () => {
      props.onExpandCollapseClick(EXPAND_ALL_STATE);
      return ANALYTICS_FOR_USER.EXPAND_ALL_TABS;
    },
    onCollapseAll: () => {
      props.onExpandCollapseClick(COLLAPSE_ALL_STATE);
      return ANALYTICS_FOR_USER.MINIMIZE_ALL_TABS;
    },
    onSearchClick: () => MAIN_MENU_FIRST_PHASE.CLICK_SEARCH,
    onCreateDpdClick: () => MAIN_MENU_FIRST_PHASE.CLICK_CREATE_DPD_USER,
    onCreateUserClick: () => MAIN_MENU_FIRST_PHASE.CLICK_CREATE_USER,
    onProfileManagementClick: () =>
      MAIN_MENU_FIRST_PHASE.CLICK_PROFILE_MANAGEMENT,
  }))
)(DpdUsersMenu);
