import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle, withProps } from "recompose";
import { reduxForm, FieldArray } from "redux-form";

import { CheckBoxGroup } from "../../components/CheckBoxGroup/CheckBoxGroup";

import CustomTabs from "../../components/CustomTabs/CustomTabs";
import TransferList from "../../components/TransferList/TransferList";
import {
  ANALYTICS_FOR_USER,
  CREATE_EDIT_CUSTOMER_USER_ANALYTICS,
} from "../../constants/analytics";
import colors from "../../constants/colors";
import { USER_FORMS } from "../../constants/forms";
import {
  DPD_SERVICE_LEFT_HEADER,
  DPD_SERVICE_RIGHT_HEADER,
  SERVICE_EXCLUSIONS_TABS,
  SPECIAL_SERVICES,
} from "../../constants/strings";
import { onChangeExclusions } from "../../features/userDetailsForm/serviceExclusions/serviceExclusions.actions";
import { DPD_SPECIAL_SERVICES } from "../../features/userDetailsForm/serviceExclusions/serviceExclusions.constants";
import {
  dpdLocalServicesFormValues,
  dpdServicesFormValues,
  getDpdAvailableToSelectServices,
  getDpdLocalAvailableToSelectServices,
} from "../../features/userDetailsForm/userDetailsForm.selector";
import { getCustomerData } from "../../features/userDetailsForm/accountDetails/accountDetails.selectors";
import { fetchAccountSettings } from "../../features/userDetailsForm/userDetailsForm.actions";
import {
  getDPDSpecialServices,
  getDpdLocalSpecialServiceFormValues,
} from "../../features/userDetailsForm/serviceExclusions/serviceExclusions.selectors";
import { isCurrentUserDpdAdmin } from "../../features/user/user.selectors";
import styles from "./ServiceExclusions.module.scss";

const useThemeStyles = makeStyles(() => ({
  item: {
    backgroundColor: colors.uiLocalPrimary,
    "&:hover": {
      backgroundColor: colors.uiLocalPrimary,
    },
  },
}));

const CheckBoxGroupContainer = ({ ...rest }) => (
  <Box display="flex" flexDirection="column" justifyContent="space-between">
    <CheckBoxGroup {...rest} />
  </Box>
);

const SpecialServicesExclusions = ({
  dpdSpecialServices,
  disableSpecialService,
}) => (
  <Box display="flex" flexDirection="column" container mt={6}>
    <Divider />
    <Box display="flex" flexDirection="row" width="100%" mb={2} mt={4}>
      <Typography className={styles["title-text"]}>
        {SPECIAL_SERVICES}:
      </Typography>
    </Box>
    <Grid item container direction="column">
      <FieldArray
        name={DPD_SPECIAL_SERVICES}
        disabled={disableSpecialService}
        component={CheckBoxGroupContainer}
        checkBoxData={dpdSpecialServices}
      />
    </Grid>
  </Box>
);

const ServiceExclusions = ({
  onChangeDpd,
  onInputChange,
  onChangeDpdLocal,
  dpdSelectedServices,
  dpdLocalSelectedServices,
  dpdAvailableToSelectServices,
  dpdLocalAvailableToSelectServices,
  analyticIdsDpd,
  analyticsIdsDpdLocal,
  dpdSpecialServices,
  dpdLocalSpecialServices,
  disableSpecialService,
}) => {
  const themeStyles = useThemeStyles(useTheme());

  return (
    <>
      <CustomTabs tabs={SERVICE_EXCLUSIONS_TABS}>
        <>
          <TransferList
            leftHeader={DPD_SERVICE_LEFT_HEADER}
            rightHeader={DPD_SERVICE_RIGHT_HEADER}
            onChange={(value) => {
              onChangeDpd(value);
              onInputChange(value);
            }}
            selectedValues={dpdSelectedServices}
            availableValues={dpdAvailableToSelectServices}
            {...analyticIdsDpd}
          />
          {!!dpdSpecialServices.length && (
            <SpecialServicesExclusions
              dpdSpecialServices={dpdSpecialServices}
              disableSpecialService={disableSpecialService}
            />
          )}
        </>
        <>
          <TransferList
            leftHeader={DPD_SERVICE_LEFT_HEADER}
            rightHeader={DPD_SERVICE_RIGHT_HEADER}
            rightItemsStyles={themeStyles.item}
            onChange={(value) => {
              onChangeDpdLocal(value);
              onInputChange(value);
            }}
            selectedValues={dpdLocalSelectedServices}
            availableValues={dpdLocalAvailableToSelectServices}
            {...analyticsIdsDpdLocal}
          />
          {!!dpdLocalSpecialServices.length && (
            <SpecialServicesExclusions
              dpdSpecialServices={dpdLocalSpecialServices}
              disableSpecialService={disableSpecialService}
            />
          )}
        </>
      </CustomTabs>
    </>
  );
};

ServiceExclusions.defaultProps = {
  onInputChange: () => null,
  analyticIdsDpd: {},
  analyticsIdsDpdLocal: {},
};

ServiceExclusions.propTypes = {
  onChangeDpd: PropTypes.func,
  onInputChange: PropTypes.func,
  onChangeDpdLocal: PropTypes.func,
  dpdSelectedServices: PropTypes.array,
  dpdLocalSelectedServices: PropTypes.array,
  dpdAvailableToSelectServices: PropTypes.array,
  dpdLocalAvailableToSelectServices: PropTypes.array,
  analyticIdsDpd: PropTypes.object,
  analyticsIdsDpdLocal: PropTypes.object,
};

const mapStateToProps = (state) => ({
  customer: getCustomerData(state),
  dpdSelectedServices: dpdServicesFormValues(state),
  dpdLocalSelectedServices: dpdLocalServicesFormValues(state),
  dpdAvailableToSelectServices: getDpdAvailableToSelectServices(state),
  dpdLocalAvailableToSelectServices:
    getDpdLocalAvailableToSelectServices(state),
  dpdSpecialServices: getDPDSpecialServices(state),
  dpdLocalSpecialServices: getDpdLocalSpecialServiceFormValues(state),
  disableSpecialService: !isCurrentUserDpdAdmin(state),
});

const analyticIdsDpd = {
  analyticIdAddAll: ANALYTICS_FOR_USER.EXCLUDED_SERVICES_ADD_ALL_DPD_SERVICES,
  analyticIdRemoveAll:
    ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_ALL_DPD_SERVICES,
  analyticIdRemove: ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_DPD_SERVICE,
  analyticIdAdd: ANALYTICS_FOR_USER.EXCLUDED_SERVICES_ADD_DPD_SERVICE,
};

const analyticsIdsDpdLocal = {
  analyticIdAddAll:
    CREATE_EDIT_CUSTOMER_USER_ANALYTICS.EXCLUDED_SERVICES_ADD_ALL_DPD_LOCAL_SERVICES,
  analyticIdRemoveAll:
    ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_ALL_DPD_LOCAL_SERVICES,
  analyticIdRemove:
    ANALYTICS_FOR_USER.EXCLUDED_SERVICES_REMOVE_DPD_LOCAL_SERVICE,
  analyticIdAdd:
    CREATE_EDIT_CUSTOMER_USER_ANALYTICS.EXCLUDED_SERVICES_ADD_DPD_LOCAL_SERVICE,
};

const mapDispatchToProps = (dispatch) => ({
  onChangeDpd: (selectedItems) =>
    dispatch(onChangeExclusions(selectedItems, "dpd")),
  onChangeDpdLocal: (selectedItems) =>
    dispatch(onChangeExclusions(selectedItems, "dpdLocal")),
  fetchAccountSettings: (account, businessId) =>
    dispatch(fetchAccountSettings(account, businessId)),
});

export default compose(
  withProps({
    analyticIdsDpd,
    analyticsIdsDpdLocal,
  }),
  reduxForm({
    form: USER_FORMS.SERVICE_EXCLUSIONS,
    initialValues: {
      dpdSpecialServices: [],
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidUpdate(prevProps) {
      if (
        this.props.customer?.account &&
        prevProps.customer?.account !== this.props.customer?.account
      ) {
        const { account, business } = this.props.customer;

        await this.props.fetchAccountSettings(account, business);
      }
    },
  })
)(ServiceExclusions);
