import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import * as configService from "./config.service";

const ACTION_NAMESPACE = "CONFIG";

export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  FETCH_CONFIG: createAsyncActionTypes("FETCH_CONFIG"),
  FETCH_CURRENCIES: createAsyncActionTypes("FETCH_CURRENCIES"),
  FETCH_DPD_SERVICES: createAsyncActionTypes("FETCH_DPD_SERVICES"),
  FETCH_SERVICE_RESTRICTIONS: createAsyncActionTypes(
    "FETCH_SERVICE_RESTRICTIONS"
  ),
  FETCH_SPECIAL_DPD_SERVICES: createAsyncActionTypes(
    "FETCH_SPECIAL_DPD_SERVICES"
  ),
  FETCH_SERVICE_RESTRICTION_TEMPLATES: createAsyncActionTypes(
    "FETCH_SERVICE_RESTRICTION_TEMPLATES"
  ),
  FETCH_APP_VERSIONS: createAsyncActionTypes("FETCH_APP_VERSIONS"),
});

export const fetchDpdServices = createAsyncAction(
  configService.getDpdServices,
  ACTIONS.FETCH_DPD_SERVICES
);

export const fetchServiceRestrictions = createAsyncAction(
  configService.getServiceRestrictions,
  ACTIONS.FETCH_SERVICE_RESTRICTIONS
);

export const fetchSpecialDpdService = createAsyncAction(
  configService.getSpecialDpdService,
  ACTIONS.FETCH_SPECIAL_DPD_SERVICES
);

export const fetchServiceRestrictionTemplates = createAsyncAction(
  configService.getServiceRestrictionTemplates,
  ACTIONS.FETCH_SERVICE_RESTRICTION_TEMPLATES
);

export const fetchCurrencies = createAsyncAction(
  configService.getCurrencies,
  ACTIONS.FETCH_CURRENCIES
);

export const fetchAppVersions = createAsyncAction(
  configService.fetchAppVersions,
  ACTIONS.FETCH_APP_VERSIONS
);
