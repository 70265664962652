import { DPD_SPECIAL_SERVICES } from "./serviceExclusions.constants";

export const prepareServiceExclusions = (formValues) => ({
  serviceExclusions: [
    ...(formValues.dpd || []),
    ...(formValues.dpdLocal || []),
  ].map(({ code }) => code),
  excludeSpecialServices: formValues[DPD_SPECIAL_SERVICES],
});

export const mapAndFilterSpecialServices = (
  specialServices,
  accountSettings,
  businessId
) => {
  if (accountSettings.businessId !== businessId) {
    return [];
  }

  return specialServices
    .filter((service) => accountSettings.specialServices.includes(service.code))
    .map((item) => ({
      ...item,
      label: `Disable ${item.label}`,
    }));
};
