import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { Divider, Grid } from "@material-ui/core";
import { withTrack } from "@dpdgroupuk/react-event-tracker";
import { HOME_PAGE_ANALYTICS } from "../../../../constants/analytics";
import { CUSTOMER_SETTINGS } from "../../../../constants/strings";
import GradientHeader from "../../../../components/GradientHeader/GradientHeader";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import AccountSettingsForm from "../../components/AccountSettingsForm";
import AccountSettingsSearchForm from "../../components/AccountSettingsSearchForm";
import {
  onSearchAccount,
  resetCustomerSettingsForm,
  onSaveAccountSettings,
} from "../../../../features/customerSettings/customerSettings.action";

import styles from "./CustomerSettings.module.scss";

const CustomerSettings = ({
  allowAccountSettings,
  onSearchAccount,
  onSearchFormChange,
  onSaveAccountSettings,
}) => (
  <PageContainer>
    <GradientHeader title={CUSTOMER_SETTINGS} />
    <Grid className={styles.container} container>
      <Grid item>
        <AccountSettingsSearchForm
          onSearchAccount={onSearchAccount}
          onChange={onSearchFormChange}
        />
        {allowAccountSettings && (
          <>
            <Divider />
            <Grid item>
              <AccountSettingsForm
                onSaveAccountSettings={onSaveAccountSettings}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  </PageContainer>
);

CustomerSettings.propTypes = {
  allowAccountSettings: PropTypes.bool,
  onSearchAccount: PropTypes.func,
  onSearchFormChange: PropTypes.func,
  onSaveAccountSettings: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onSearchAccount: () => dispatch(onSearchAccount()),
  resetCustomerSettingsForm: () => dispatch(resetCustomerSettingsForm()),
  onSaveAccountSettings: () => dispatch(onSaveAccountSettings()),
});

export default compose(
  withTrack({
    loadId: HOME_PAGE_ANALYTICS.LOAD,
    interfaceId: HOME_PAGE_ANALYTICS.INTERFACE_ID,
  }),
  withRouter,
  withState("allowAccountSettings", "setAllowAccountSettings", false),
  connect(null, mapDispatchToProps),
  withHandlers({
    onSearchAccount:
      ({ onSearchAccount, setAllowAccountSettings }) =>
      async () => {
        await onSearchAccount();
        setAllowAccountSettings(true);
      },
    onSearchFormChange:
      ({
        allowAccountSettings,
        setAllowAccountSettings,
        resetCustomerSettingsForm,
      }) =>
      () => {
        if (allowAccountSettings) {
          setAllowAccountSettings(false);
          resetCustomerSettingsForm();
        }
      },
  })
)(CustomerSettings);
