import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { reduxForm, FieldArray, isValid } from "redux-form";
import { Button, Grid, Box, Typography } from "@material-ui/core";

import { CUSTOMER_SETTINGS } from "../../../../constants/forms";
import { SPECIAL_SERVICES } from "../../../../constants/strings";
import { CheckBoxGroup } from "../../../../components/CheckBoxGroup/CheckBoxGroup";
import { SPECIAL_SERVICE_EXCLUSIONS_FIELD } from "../../../../features/customerSettings/customerSettings.constants";
import { getInitialCustomerSettingsFormValues } from "../../../../features/customerSettings/customerSettings.models";
import { getSpecialServiceFormValues } from "../../../../features/customerSettings/customerSettings.selectors";
import { validate } from "../../../../features/search/search.validate";
import { loadAccountSettingsFormValues } from "../../../../features/customerSettings/customerSettings.action";
import styles from "./AccountSettingsForm.module.scss";

const CheckBoxGroupContainer = ({ ...rest }) => (
  <Box
    display="flex"
    container
    flexDirection="column"
    justifyContent="space-between"
  >
    <CheckBoxGroup {...rest} />
  </Box>
);

const AccountSettingsForm = ({ specialServices, onSaveAccountSettings }) => (
  <Box display="flex" flexDirection="column" container>
    <Box display="flex" flexDirection="row" width="100%" mb={2} mt={4}>
      <Typography className={styles["title-text"]}>
        {SPECIAL_SERVICES}:
      </Typography>
    </Box>
    <Box>
      <FieldArray
        name={SPECIAL_SERVICE_EXCLUSIONS_FIELD}
        component={CheckBoxGroupContainer}
        checkBoxData={specialServices}
      />
    </Box>
    <Grid item container md={2} xs={12} justify="flex-start">
      <Box mt={3}>
        <Button onClick={onSaveAccountSettings}>
          <span className={styles.buttonText}>SAVE</span>
        </Button>
      </Box>
    </Grid>
  </Box>
);

AccountSettingsForm.propTypes = {
  specialServices: PropTypes.array,
  onSaveAccountSettings: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isFormValid: isValid(CUSTOMER_SETTINGS)(state),
  specialServices: getSpecialServiceFormValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadFormValues: () => dispatch(loadAccountSettingsFormValues()),
});

export default compose(
  withRouter,
  reduxForm({
    form: CUSTOMER_SETTINGS,
    initialValues: getInitialCustomerSettingsFormValues(),
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.loadFormValues();
    },
  })
)(AccountSettingsForm);
