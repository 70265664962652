import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, isValid } from "redux-form";
import { compose, withHandlers, withState } from "recompose";
import { Box, Button, Grid, MenuItem } from "@material-ui/core";

import DpdIcon from "../../../../assets/icons/dpd.svg";
import DpdIconLocal from "../../../../assets/icons/dpd-local.svg";
import {
  ACCOUNT_NUMBER,
  BUSINESS_ID,
  DPD,
  DPD_LOCAL,
  SEARCH,
} from "../../../../constants/strings";
import { CUSTOMER_SETTINGS_SEARCH_FORM } from "../../../../constants/forms";
import withErrorTooltip from "../../../../hocs/withErrorTooltip";
import HashIcon from "../../../../components/HashIcon/HashIcon";
import SelectWithTitle from "../../../../components/SelectWithTitle/SelectWithTitle";
import SelectItem from "../../../../components/SelectItem/SelectItem";
import InputWithTitle from "../../../../components/InputWithTitle/InputWithTitle";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../../../../features/userDetailsForm/accountDetails/accountDetails.constants";
import {
  getInitialCustomerSettingsSearchFormValues,
  validateSearchFormValues,
} from "../../../../features/customerSettings/customerSettings.models";
import {
  ACCOUNT_FIELD,
  BUSINESS_ID_FIELD,
} from "../../../../features/customerSettings/customerSettings.constants";
import { clearAccountField } from "../../../../features/customerSettings/customerSettings.action";
import {
  getCustomerSettingsSearchFormValues,
  isSubmittingSearchForm,
} from "../../../../features/customerSettings/customerSettings.selectors";
import styles from "./AccountSettingsSearchForm.module.scss";

const DecoratedFieldWithError = withErrorTooltip(InputWithTitle);

const AccountSettingsSearchForm = ({
  isFormValid,
  disableInputField,
  handleSubmit,
  onSearchClick,
  onEnterClick,
  onClickClearAccount,
  onChangeBusinessId,
}) => (
  <>
    <Grid className={styles.container} container>
      <Grid item md={4} xs={12}>
        <Box mb={4}>
          <Field
            component={SelectWithTitle}
            label={BUSINESS_ID}
            name={BUSINESS_ID_FIELD}
            onChange={onChangeBusinessId}
          >
            <MenuItem value={DPD_BUSINESS_ID}>
              <SelectItem icon={DpdIcon} title={DPD} />
            </MenuItem>
            <MenuItem value={DPD_LOCAL_BUSINESS_ID}>
              <SelectItem icon={DpdIconLocal} title={DPD_LOCAL} />
            </MenuItem>
          </Field>
        </Box>
      </Grid>

      <Grid item md={5} xs={12} mb={4}>
        <Box mb={4}>
          <Field
            name={ACCOUNT_FIELD}
            component={DecoratedFieldWithError}
            label={ACCOUNT_NUMBER}
            icon={<HashIcon />}
            onClearClick={onClickClearAccount}
            disabled={isFormValid && disableInputField}
            onKeyDown={(e) => e.keyCode === 13 && onEnterClick()}
          />
        </Box>
      </Grid>

      <Grid item md={2} xs={12} justify="flex-end">
        <Box mb={4}>
          <Button onClick={handleSubmit(onSearchClick)}>
            <span className={styles.buttonText}>{SEARCH}</span>
          </Button>
        </Box>
      </Grid>
    </Grid>
  </>
);

AccountSettingsSearchForm.propTypes = {
  isFormValid: PropTypes.bool,
  disableInputField: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSearchClick: PropTypes.func,
  onEnterClick: PropTypes.func,
  onClickClearAccount: PropTypes.func,
  onChangeBusinessId: PropTypes.func,
};

const mapStateToProps = (state) => ({
  values: getCustomerSettingsSearchFormValues(state),
  isFormValid: isValid(CUSTOMER_SETTINGS_SEARCH_FORM)(state),
  isSubmitting: isSubmittingSearchForm(state),
});

export default compose(
  withState("disableInputField", "setDisableInputField", false),
  reduxForm({
    form: CUSTOMER_SETTINGS_SEARCH_FORM,
    initialValues: getInitialCustomerSettingsSearchFormValues(),
    validate: validateSearchFormValues,
  }),
  connect(mapStateToProps),
  withHandlers({
    onSearchClick:
      ({ onSearchAccount, setDisableInputField }) =>
      async (values) => {
        setDisableInputField(true);
        await onSearchAccount(values);
      },
    onEnterClick:
      ({ onSearchAccount, setDisableInputField }) =>
      (values) => {
        setDisableInputField(true);
        onSearchAccount(values);
      },
    onClickClearAccount:
      ({ setDisableInputField, onChangeForm, dispatch }) =>
      () => {
        setDisableInputField(false);
        dispatch(clearAccountField());
        onChangeForm && onChangeForm();
      },
    onChangeBusinessId:
      ({ setDisableInputField, onChangeForm }) =>
      () => {
        setDisableInputField(false);
        onChangeForm && onChangeForm();
      },
  })
)(AccountSettingsSearchForm);
