import { createSelector } from "reselect";
import { getUserForm } from "../userDetailsForm.selector";
import * as configSelector from "../../config/config.selectors";
import * as ServiceExclusionsModel from "./serviceExclusions.model";
import {
  DPD_BUSINESS_ID,
  DPD_LOCAL_BUSINESS_ID,
} from "../accountDetails/accountDetails.constants";

export const getAccountSettings = createSelector(
  getUserForm,
  (form) => form.accountSettings
);

export const getDPDSpecialServices = createSelector(
  configSelector.getDpdSpecialServiceFormValues,
  getAccountSettings,
  (specialServices, accountSettings) =>
    ServiceExclusionsModel.mapAndFilterSpecialServices(
      specialServices,
      accountSettings,
      DPD_BUSINESS_ID
    )
);

export const getDpdLocalSpecialServiceFormValues = createSelector(
  configSelector.getDpdLocalSpecialServiceFormValues,
  getAccountSettings,
  (specialServices, accountSettings) =>
    ServiceExclusionsModel.mapAndFilterSpecialServices(
      specialServices,
      accountSettings,
      DPD_LOCAL_BUSINESS_ID
    )
);
