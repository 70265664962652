import { createSelector } from "reselect";
import { getFormValues, isDirty, isSubmitting } from "redux-form";

import {
  getDpdSpecialServiceFormValues,
  getDpdLocalSpecialServiceFormValues,
} from "../config/config.selectors";
import { DPD_BUSINESS_ID } from "../userDetailsForm/accountDetails/accountDetails.constants";
import { CUSTOMER_SETTINGS_SEARCH_FORM } from "../../constants/forms";

export const isSubmittingSearchForm = createSelector(
  isDirty(CUSTOMER_SETTINGS_SEARCH_FORM),
  isSubmitting(CUSTOMER_SETTINGS_SEARCH_FORM),
  (isDirty, isSubmitting) => isDirty && isSubmitting
);

export const getCustomerSettingsSearchFormValues = getFormValues(
  CUSTOMER_SETTINGS_SEARCH_FORM
);

const mapSpecialService = (specialServices) =>
  specialServices.map((item) => ({
    ...item,
    label: `Enable ${item.name}`,
  }));

const getCustomerDpdSpecialServiceFormValues = createSelector(
  getDpdSpecialServiceFormValues,
  mapSpecialService
);

const getCustomerDpdLocalSpecialServiceFormValues = createSelector(
  getDpdLocalSpecialServiceFormValues,
  mapSpecialService
);

export const getSpecialServiceFormValues = createSelector(
  getCustomerSettingsSearchFormValues,
  getCustomerDpdSpecialServiceFormValues,
  getCustomerDpdLocalSpecialServiceFormValues,
  (customerSettingsSearchValues, dpdSpecialServices, dpdLocalSpecialServices) =>
    customerSettingsSearchValues?.businessId === DPD_BUSINESS_ID
      ? dpdSpecialServices
      : dpdLocalSpecialServices
);
