import { getFormValues, SubmissionError } from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";

import {
  _NO_USER_ACCOUNT,
  MY_DPD_LOGIN_PAGE,
  RESET_TOKEN_EXPIRED,
  SOCIAL_LOGIN_TYPES,
  UNABLE_TO_AUTHORISE_CONTACT_SUPPORT,
} from "./auth.constants";
import { getSignInWithPasswordError } from "./auth.errors";
import * as authModel from "./auth.model";
import { getFirstLoginEmail } from "./auth.selectors";
import * as authService from "./auth.service";
import {
  ADMIN_FIRST_LOGIN_MODAL,
  LOGOUT_MODAL,
} from "../../constants/analytics";
import { LOGIN_EMAIL_FORM } from "../../constants/forms";
import {
  ADMIN_FIRST_LOGIN_MSG,
  ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT,
  CLOSE,
  CONFIRMATION_FORGOT_PASSWORD,
  GOT_IT,
  NO,
  RESET_PASSWORD_MESSAGE,
  YES,
} from "../../constants/strings";
import {
  CUSTOMER_ACTIVITY_MONITOR,
  CUSTOMER_CREATE_USER_PAGE,
  CUSTOMER_TEAM_SETTINGS_PAGE,
  CUSTOMER_USERS_PAGE,
  LOGIN_PAGE,
  SET_PASSWORD,
} from "../../router";
import {
  externalNavigateTo,
  getUrlSearchParam,
  historyReplace,
  isUserFromInternalLink,
  navigateTo,
} from "../../router/navigation";
import { setUserProperties } from "../../utils/eventTracker";
import * as localStorageUtils from "../../utils/localStorage";
import * as sentryUtils from "../../utils/sentry";
import { getMyDpdDomain } from "../app/app.modes";
import {
  fetchCurrencies,
  fetchDpdServices,
  fetchServiceRestrictions,
  fetchSpecialDpdService,
  fetchServiceRestrictionTemplates,
} from "../config/config.actions";
import { createLoadingAction } from "../loader/loader.actions";
import {
  showInfoModal,
  showModal,
  showModalWithDelay,
} from "../modal/modal.actions";
import { ALIGN_BUTTONS } from "../modal/modal.constants";
import { updateAdminAccessCustomerAccountByID } from "../myDpdUserForm/systemAccess/systemAccess.actions";
import {
  createAsyncErrorAction,
  showSnackbar,
} from "../snackbar/snackbar.actions";
import * as userActions from "../user/user.actions";
import {
  isAdminFirstLogin,
  isCustomerAdmin,
  isDpdAdmin,
  isDpdUser,
} from "../user/user.model";
import { getCurrentUser, getCustomerAccountId } from "../user/user.selectors";
import * as userService from "../user/user.service";

const ACTION_NAMESPACE = "LOGIN";
export const ACTIONS = createActionTypes(ACTION_NAMESPACE, {
  HANDLE_SOCIAL_SIGN_IN: createAsyncActionTypes("HANDLE_SOCIAL_SIGN_IN"),
  SIGN_IN_WITH_SAML: createAsyncActionTypes("SIGN_IN_WITH_SAML"),
  SIGN_IN_WITH_PASSWORD: createAsyncActionTypes("SIGN_IN_WITH_PASSWORD"),
  CHECK_AUTH: createAsyncActionTypes("CHECK_AUTH"),
  SET_FROM_REDIRECT: createAsyncActionTypes("SET_FROM_REDIRECT"),
  SIGN_OUT: createAsyncActionTypes("SIGN_OUT"),
  SET_FIRST_PASSWORD: createAsyncActionTypes("SET_FIRST_PASSWORD"),
  SET_FIRST_LOGIN_EMAIL: "SET_FIRST_LOGIN_EMAIL",
  CHECK_CREATE_USERS_VALID: createAsyncActionTypes("CHECK_CREATE_USERS_VALID"),
  CHECK_RESET_PASSWORD: createAsyncActionTypes("CHECK_RESET_PASSWORD"),
  RESET_PASSWORD: createAsyncActionTypes("RESET_PASSWORD"),
  REQUEST_RESET_PASSWORD: createAsyncActionTypes("REQUEST_RESET_PASSWORD"),
  CHECK_USER_EMAIL: createAsyncActionTypes("CHECK_USER_EMAIL"),
});

export const onCustomerAccountClick = (account, match) => (dispatch) => {
  const { path } = match;

  if (path === CUSTOMER_CREATE_USER_PAGE) {
    dispatch(updateAdminAccessCustomerAccountByID(account));
  }

  navigateTo(
    path === CUSTOMER_ACTIVITY_MONITOR
      ? CUSTOMER_ACTIVITY_MONITOR
      : CUSTOMER_USERS_PAGE,
    { customerId: account }
  );
};

export const handleSocialSignIn = createLoadingAction(
  createAsyncErrorAction(
    authService.signInWithSocial,
    ACTIONS.HANDLE_SOCIAL_SIGN_IN
  )
);

export const checkIsUserFromRedirect = createAsyncErrorAction(
  authService.isUserFromRedirect,
  ACTIONS.SET_FROM_REDIRECT
);

export const signInWithSaml = createLoadingAction(
  createAsyncErrorAction(authService.signInWithSaml, ACTIONS.SIGN_IN_WITH_SAML)
);

export const signInWithPassword = createLoadingAction(
  createAsyncAction(
    ({ email, password }) =>
      async (dispatch) => {
        try {
          await authService.loginWithPassword(
            authModel.getSignInEmail(email),
            authModel.preparePassword(password, email)
          );
          dispatch(fetchAppData());
        } catch (error) {
          const message = getSignInWithPasswordError(error);

          if (message === UNABLE_TO_AUTHORISE_CONTACT_SUPPORT) {
            return dispatch(showSnackbar({ message }));
          }
          await authService.failedLogin(email);

          throw new SubmissionError({
            email: message,
          });
        }
      },
    ACTIONS.SIGN_IN_WITH_PASSWORD
  )
);

export const setFirstLoginEmail = (email) =>
  createPayloadAction(ACTIONS.SET_FIRST_LOGIN_EMAIL, email);

export const onLoginFirstSubmit = createLoadingAction(
  createAsyncAction(
    (uid) => async (dispatch, getState) => {
      const { email } = getFormValues(LOGIN_EMAIL_FORM)(getState());
      const preparedEmail = email.toLowerCase();

      try {
        await authService.isUserExist(uid, preparedEmail);
      } catch (e) {
        return dispatch(navigateWithSnackbar(_NO_USER_ACCOUNT));
      }

      dispatch(setFirstLoginEmail(preparedEmail));
      historyReplace(SET_PASSWORD); // Use replace instead of push to disable browser "Back" button
    },
    ACTIONS.CHECK_USER_EMAIL
  )
);

const navigateWithSnackbar = (message) => (dispatch) => {
  navigateTo(LOGIN_PAGE);
  dispatch(showSnackbar({ message }));
};

export const onLoginFirstInit = createLoadingAction(
  createAsyncAction(
    () => async (dispatch) => {
      const link = window.location.href;
      const linkIsValid = await authService.requestValidateLink(link);
      if (!linkIsValid) {
        return dispatch(navigateWithSnackbar(RESET_TOKEN_EXPIRED));
      }
      return true;
    },
    ACTIONS.CHECK_CREATE_USERS_VALID
  )
);

export const onSetFirstPasswordSubmit = createLoadingAction(
  createAsyncAction(
    (password) => async (dispatch, getState) => {
      const state = getState();
      const email = getFirstLoginEmail(state);
      const user = await authService.createAuthUserByEmailAndPassword(
        email,
        password
      );
      if (user) {
        const isMyDPDUser = isDpdUser(user);
        const isAdmin = isCustomerAdmin(user) || isDpdAdmin(user);
        if (isMyDPDUser && !isAdmin) {
          externalNavigateTo(`${getMyDpdDomain()}${MY_DPD_LOGIN_PAGE}`);
        } else {
          navigateTo(LOGIN_PAGE);
        }
      }
    },
    ACTIONS.SET_FIRST_PASSWORD
  )
);

const requestForgotPassword = createLoadingAction(
  createAsyncErrorAction(
    (email) => authService.requestForgotPassword(encodeURIComponent(email)),
    ACTIONS.REQUEST_RESET_PASSWORD
  )
);

// don't use finally it doesn't support in IE and old EDGE
export const onForgotPasswordSubmit = () => async (dispatch, getState) => {
  const { email } = getFormValues(LOGIN_EMAIL_FORM)(getState());
  try {
    await dispatch(requestForgotPassword(email.toLowerCase()));
  } catch (e) {}
  navigateTo(LOGIN_PAGE);

  return dispatch(
    showModalWithDelay({
      confirmButtonText: CLOSE,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
      contentText: CONFIRMATION_FORGOT_PASSWORD,
    })
  );
};

export const onResetPasswordSubmit = createLoadingAction(
  createAsyncAction(
    (password) => async (dispatch) => {
      const myDpdUser = getUrlSearchParam("myDpdUser");
      const email = getUrlSearchParam("email");
      const uid = getUrlSearchParam("uid");
      await authService.confirmResetPassword(password, email, uid);
      dispatch(
        showInfoModal(RESET_PASSWORD_MESSAGE, {
          onConfirmClick: () => {
            if (myDpdUser === "true") {
              return navigateTo(LOGIN_PAGE);
            }
            return externalNavigateTo(
              `${getMyDpdDomain()}${MY_DPD_LOGIN_PAGE}`
            );
          },
        })
      );
    },
    ACTIONS.RESET_PASSWORD
  )
);

export const fetchAppData = createLoadingAction(() => async (dispatch) => {
  try {
    const user = await dispatch(userActions.fetchCurrentUser());
    setUserProperties(user);
    sentryUtils.setSentryUser({
      email: user.email,
      displayName: user.username,
      uid: user.uid,
    });
    user.businessId && localStorageUtils.setItem("userTheme", user.businessId);

    if (isCustomerAdmin(user) && isAdminFirstLogin(user)) {
      dispatch(openAdminFirstLoginDialog(user.uid));
    }
    dispatch(fetchCurrencies());
    dispatch(fetchDpdServices());
    dispatch(fetchServiceRestrictions());
    dispatch(fetchSpecialDpdService());
    return dispatch(fetchServiceRestrictionTemplates());
  } catch (e) {
    if (!window.location.href.includes(LOGIN_PAGE)) {
      navigateTo(LOGIN_PAGE);
    }
  }
});

export const checkAuth = createAsyncAction(
  () => async (dispatch, getState) => {
    try {
      const { credential, user } = await dispatch(checkIsUserFromRedirect());

      if (credential && credential.idToken) {
        const idToken = await user.getIdToken();

        await authService.loginWithIdToken(idToken, user.email);

        return true;
      }
    } catch (e) {
      return dispatch(showSnackbar({ message: e.message || e }));
    }

    const currentUser = getCurrentUser(getState());

    if (!currentUser && isUserFromInternalLink()) {
      return dispatch(handleSocialSignIn(SOCIAL_LOGIN_TYPES.GOOGLE));
    }
  },
  ACTIONS.CHECK_AUTH
);

export const signOut = createLoadingAction(
  createAsyncAction(
    () => (dispatch) => {
      dispatch(userActions.clearUserData());
      return authService.logout();
    },
    ACTIONS.SIGN_OUT
  )
);

export const openAdminFirstLoginDialog = (uid) => async (dispatch) => {
  await userService.userFirstLogin(uid);
  dispatch(
    showModalWithDelay({
      loadId: ADMIN_FIRST_LOGIN_MODAL.LOAD,
      interfaceId: ADMIN_FIRST_LOGIN_MODAL.INTERFACE_ID,
      confirmActionId: ADMIN_FIRST_LOGIN_MODAL.GOT_IT,
      contentText: ADMIN_FIRST_LOGIN_MSG,
      confirmButtonText: GOT_IT,
      showCancelButton: false,
      alignButton: ALIGN_BUTTONS.CENTER,
      onConfirmClick: () => {
        dispatch(navigateToTeamSettingsPage());
      },
    })
  );
};

export const navigateToTeamSettingsPage = () => (dispatch, getState) => {
  const customerId = getCustomerAccountId(getState());
  return navigateTo(CUSTOMER_TEAM_SETTINGS_PAGE, {
    customerId,
  });
};

export const openSignOutDialog = () => (dispatch) => {
  dispatch(
    showModal({
      loadId: LOGOUT_MODAL.LOAD,
      interfaceId: LOGOUT_MODAL.INTERFACE_ID,
      cancelActionId: LOGOUT_MODAL.CANCEL,
      confirmActionId: LOGOUT_MODAL.CONFIRM,
      contentText: ARE_YOU_SURE_YOU_WANT_TO_SIGN_OUT,
      cancelButtonText: NO,
      confirmButtonText: YES,
      onConfirmClick: () => {
        dispatch(signOut());
      },
    })
  );
};

export const checkResetPasswordCode = createLoadingAction(
  createAsyncAction(
    () => async (dispatch) => {
      const link = window.location.href;
      const linkIsValid = await authService.requestValidateLink(link);
      if (!linkIsValid) {
        navigateTo(LOGIN_PAGE);
        dispatch(showSnackbar({ message: RESET_TOKEN_EXPIRED }));
      }
      return linkIsValid;
    },
    ACTIONS.CHECK_RESET_PASSWORD
  )
);
