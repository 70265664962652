import { API_ENDPOINTS } from "../../constants/common";
import { webApi } from "../../utils/api";

export const fetchConfig = () =>
  webApi.get(API_ENDPOINTS.CONFIG).then(({ data }) => data);

export const getCurrencies = () =>
  webApi.get(`${API_ENDPOINTS.REFERENCE}/currencies`).then(({ data }) => data);

export const getDpdServices = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CONFIG}/dpd-services`,
    })
    .then(({ data }) => data);

export const getSpecialDpdService = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CONFIG}/special-dpd-service`,
    })
    .then(({ data }) => data);

export const getServiceRestrictions = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CONFIG}/service-restrictions`,
    })
    .then(({ data }) => data);

export const getServiceRestrictionTemplates = () =>
  webApi
    .get({
      path: `${API_ENDPOINTS.CONFIG}/service-restriction-templates`,
    })
    .then(({ data }) => data);

export const fetchAppVersions = () =>
  webApi
    .get(`${API_ENDPOINTS.CONFIG}/dpd-app/versions/available`)
    .then(({ data }) => data);

export const getAddressByPostcode = (postcode) =>
  webApi
    .get({
      path: `${API_ENDPOINTS.REFERENCE}/addresses/`,
      query: {
        postcode,
        pageSize: 50,
      },
    })
    .then(({ data }) => data);
